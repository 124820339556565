import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/styles";
import { withStyles } from "@mui/styles";
import {
  TicketStatusView,
  TicketsTableView,
  TicketSelectedView,
  TicketNewView,
  TicketFollowing,
} from "../Tickets";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";

import noBio from "../../assets/NoBiometricos.png";
import noDataToShow from "../../assets/noDataToShow.svg";

import {
  workFlowActions,
  fileActions,
  biometricActions,
  validationActions,
  documentActions,
  ticketActions,
  notificationActions,
} from "../../store/actions";
import { useStyles } from "./TabsStyle";
import { mainStyles } from "../../theme/mainStyles";
import {
  GeneralInfo,
  ActorGralInfo,
  Documentation,
  RecordInfo,
  Biometrics,
  EquivalenciasTables,
} from "./index";
import { TableBinnacle } from "../table";
import {
  UseImage,
  FormDynamic,
  CustomLoadingOverlay,
  DocumentUploadComponent,
  InformationalMessageComponent,
  GenericHeaderActions,
} from "../index";
import { CustomAlert } from "../CustomAlert";
import moment from "moment";
import NoShares from "../NoShares";
import { ExtractHour, Logout, routes } from "../../utils";
import { Alert } from "../Alert";
import { FormDynamicTemp } from "../dynamic/FormDynamicTemp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export function FullWidthTabs({ currentActor, actorId, multipleActors }) {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const navigation = useNavigate();
  const history = useNavigate();
  const noInfo = mainStyles.noDataSections();

  const [actorCards, setActorCards] = useState([]);
  const [docsCard, setDocsCard] = useState([]);
  const [validation, setValidation] = useState({});
  const [docsApproved, setDocsApproved] = useState([]);
  const [listEquivalencias, setListEquivalencias] = useState([]);
  const [value, setValue] = useState(0);
  const [statusView, setStatusView] = useState("status");
  const [ticketSelected, setTicketSelected] = useState({});

  const [openHelpFinalice, setOpenHelpFinalice] = useState(false);
  const [openHelpFinReject, setOpenHelpFinReject] = useState(false);
  const [wfContinueRequest, setWFContinueRequest] = useState(false);
  const [wfRejectRequest, setWFRejectRequest] = useState(false);
  const [fileUpdateMetFile, setFileUpdateMetFile] = useState(false);
  const [approvedValideProcess, setApprovedValideProcess] = useState(false);
  const [viewButtonFinalice, setViewButtonFinalice] = useState(true);
  const [showAlertLogout, setShowAlertLogout] = useState(true);

  const [requestFormTemp, setRequestFormTemp] = useState(null);
  const [fileInfoTemp, setFileInfoTemp] = useState(null);

  const [sendCuestionarioContinue, setSendCuestionarioContinue] =
    useState(false);
  const [sendedFromTemp, setSendedFromTemp] = useState(false);

  const dispatch = useDispatch();
  let path = window.location.pathname;

  const handleChange = (event, newValue) => {
    setStatusView("status");
    setFileInfoTemp(infoFile.items);
    setValue(newValue);
    getInfoDispatch();
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const { id } = useParams();
  const actorBody = {
    File_Id: id,
    Actor_Id: actorId,
  };

  const actor = useSelector((state) => state.ActorInfo);
  const fileUpdateData = useSelector((state) => state.FileUpdateMetData);
  const processRulesData = useSelector((state) => state.ProcessValRulesData);
  const rolUser = useSelector(
    (state) =>
      state.AuthenticationReferer.items?.UserLoginData?.SecurityLoginData
        ?.Roles[0]?.Name
  );
  const userFuntions = useSelector(
    (state) =>
      state.AuthenticationReferer.items?.UserLoginData?.SecurityLoginData?.Applications[0]?.Modules.find(
        (md) => md.Name == "Asignaciones"
      )?.Functions
  );
  const userLogged = useSelector(
    (state) => state.AuthenticationReferer.items?.UserLoginData?.Username
  );
  const documentsRolUser = useSelector((state) => state.GetDocumentsGroupByRol);

  const [loadView, setLoadView] = useState(true);
  const [loadViewMain, setLoadViewMain] = useState(true);

  const getInfoDispatch = () => {
    setLoadViewMain(true);
    dispatch(notificationActions.GetNotificationsUser({}));
    dispatch(fileActions.GetFileInfo(id));
  };

  const updateAssignUser = () => {
    if (
      infoFile.items?.Stage?.Properties?.find(
        (prop) => prop.Name == "AsignateCurrentUser"
      )?.Value == "1"
    ) {
      dispatch(fileActions.AsignateCurrentUserNew({ File_Id: id }));
    }
  };

  const getActorInfoDispatch = () => {
    dispatch(fileActions.GetActorInfo(actorId));
  };
  const setBioDispatch = () => {
    dispatch(biometricActions.GetBiometricData(actorBody));
  };

  const getTicketSearchDispatch = (searchText) => {
    const objSearch = {
      File_Id: infoFile?.items?.Folio,
      Busqueda: searchText,
    };
    dispatch(ticketActions.GetTicketsBySearch(objSearch));
  };

  const getTicketsStatus = () => {
    dispatch(
      ticketActions.GetTicketEstatusProceso({
        File_Id: id,
      })
    );
  };

  useEffect(() => {
    getInfoDispatch();
    dispatch(workFlowActions.GetBinnacleInfo(actorBody));
    dispatch(fileActions.GetRecordInfo(actorBody));
    dispatch(
      validationActions.GetRegisterGeolocationFile({
        File_Id: id,
      })
    );
    dispatch(validationActions.GetCustomValidationFile(actorBody));

    getActorInfoDispatch();
    setBioDispatch();
    if (rolUser === "Tickets") {
      getTicketsStatus();
      getTicketSearchDispatch("");
    }
    if (path.includes(routes.alertaNotificaciones)) {
      dispatch(
        notificationActions.GetMetadataFill({
          File_Id: id,
        })
      );
      setLoadView(true);
      setValue(2); //Redirect to Actions Tab
    }
  }, []);

  useEffect(() => {
    if (documentsRolUser.error != undefined) {
      setLoadView(false);
      if (infoFile.error == "unauthorized") {
        if (showAlertLogout) {
          setShowAlertLogout(false);
          Alert(
            {
              icon: "error",
              title: "Error de autentificación, inicia sesión nuevamente",
              allowOutsideClick: false,
              allowEscapeKey: false,
              onlyOnSuccess: true,
            },
            () => {
              console.log("On accept logout 2");
              navigation("/auth/login");
              Logout();
            }
          );
        }
      }
    } else if (documentsRolUser?.items != null) {
      /* if (
        !(
          infoFile.items.Stage?.Name === "AsignacionMetadatos" ||
          infoFile.items.Stage?.Name === "Notificación" ||
          infoFile.items.Stage?.Name === "Ejecución paralela" ||
          infoFile.items.Stage?.Name === "Consulta Acta Defuncion" ||
          infoFile.items.Stage?.Name === "Aplicando reglas de negocio" ||
          (infoFile.items.Stage?.Name === "Validación Documental" &&
            infoFile.items?.WorkFlow?.WorkflowInstance?.CurrentStatus !==
              "Validación pendiente a ejecutar por el usuario")
        )
      ) { */
      setLoadView(false);
      /* } */
    }
  }, [documentsRolUser]);

  useEffect(() => {
    if (Object.keys(currentActor).length !== 0) {
      dispatch(workFlowActions.GetBinnacleInfo(actorBody));
      dispatch(fileActions.GetRecordInfo(actorBody));
      dispatch(validationActions.GetCustomValidationFile(actorBody));
      getActorInfoDispatch();
      setBioDispatch();
    }
  }, [currentActor]);

  useEffect(() => {
    if (actor.itemsCardActor !== undefined) {
      setActorCards(actor.itemsCardActor);
    }
  }, [actor]);

  const info = useSelector((state) => state.BinnacleInfo);
  const tickets = useSelector((state) => state.TicketsBySearch);
  const ticketStatus = useSelector((state) => state.TicketEstatusProceso);
  const metadataFillNotifications = useSelector(
    (state) => state.GetMetadataFill
  );
  const [infoItems, setInfoItems] = useState([]);
  const [ticketItems, setTicketItems] = useState([]);
  const [ticketStatusItems, setTickeStatusItems] = useState([]);

  useEffect(() => {
    if (requestFormTemp != null && value != 2) {
      handleSaveTempForm(requestFormTemp);
    }
  }, [requestFormTemp]);

  const handleSaveTempForm = (formData) => {
    let ListMetdata = [];

    const qGroups =
      fileInfoTemp?.Stage?.Name == "Cuestionario dinamico Documento"
        ? fileInfoTemp?.Stage?.DocumentalCategory?.DocumentalGroups[0]
            ?.DocumentTypes[0]?.QuestionGroups
        : fileInfoTemp?.Stage?.QuestionGroups;

    qGroups?.forEach((meta, i) => {
      meta?.Questions.forEach((itemQ) => {
        ListMetdata.push({
          GroupName: meta?.Name,
          Name: itemQ?.Name,
          Value:
            itemQ?.QuestionType === "Fecha" ||
            itemQ.QuestionType === "FechaPrevia"
              ? formData[itemQ.Id] === "" ||
                formData[itemQ.Id] === undefined ||
                formData[itemQ.Id] === null
                ? null
                : moment(formData[itemQ.Id]).format("YYYY/MM/DD")
              : itemQ.QuestionType === "Currency"
              ? formData[itemQ.Id]
                  ?.replace("$", "")
                  ?.replace(",", "")
                  ?.replace(",", "")
              : itemQ?.QuestionType === "Hour"
              ? ExtractHour(formData[itemQ.Id])
              : formData[itemQ.Id],
          ApprovedConfidence: null,
          Confidence: null,
          MetadataType: null,
          ValueType: null,
        });
      });
    });

    let Request = {
      ObjectId: id,
      Metadata: ListMetdata,
      ContinueWorkFlow: false,
    };
    console.log("DATA save", Request);
    setSendCuestionarioContinue(true);
    dispatch(fileActions.FileUpdateMetadata(Request));
    setFileUpdateMetFile(true);
    setLoadView(true);
    setSendedFromTemp(true);
    // setRequestFormTemp(null);
  };

  useEffect(() => {
    if (info?.items !== undefined) {
      setInfoItems(info.items);
    }
  }, [info]);

  useEffect(() => {
    if (tickets?.items !== undefined) {
      setTicketItems(tickets.items);
    }
  }, [tickets, statusView]);

  useEffect(() => {
    if (ticketStatus?.items !== undefined) {
      setTickeStatusItems(ticketStatus.items);
    }
  }, [ticketStatus]);

  const record = useSelector((state) => state.RecordInfo);
  const [infoRecordItems, setInfoRecordItems] = useState([]);

  useEffect(() => {
    if (record.items !== undefined) {
      setInfoRecordItems(record.items);
    }
  }, [record]);

  const [biometricInfo, setBiometricInfo] = useState({});

  const infoFile = useSelector((state) => state.FileInfo);
  const biometrics = useSelector((state) => state.BiometricData);
  const wfContinueData = useSelector((state) => state.ContinueWorkFlowData);
  const wfRejectData = useSelector((state) => state.RejectWorkFlowData);
  const returnStageData = useSelector((state) => state.ReturnStageData);

  useEffect(() => {
    // setLoadView(infoFile.loading);
    if (infoFile.items !== undefined) {
      setLoadView(true);
      !fileUpdateData.loading && setLoadViewMain(false);
      dispatch(
        documentActions.GetDocumentsGroupByRol({ File_Id: infoFile.items.Id })
      );
      setDocsCard(infoFile.itemsDocCard);
      setValidation(infoFile.itemsValidation);
      setDocsApproved(infoFile.itemsDocsForApproval);
      setListEquivalencias(infoFile.itemsEquivalencias);

      if (
        infoFile.items?.Status === "Validación de Mesa de control" ||
        infoFile.items?.Status === "Validación documental" ||
        infoFile.items?.Status === "Validación de documentos"
      ) {
        setViewButtonFinalice(true);
      } else {
        setViewButtonFinalice(false);
      }
      if (
        infoFile.items.Stage?.Name === "AsignacionMetadatos" ||
        infoFile.items.Stage?.Name === "Notificación" ||
        infoFile.items.Stage?.Name === "Ejecución paralela" ||
        infoFile.items.Stage?.Name === "Consulta Acta Defuncion" ||
        infoFile.items.Stage?.Name === "Aplicando reglas de negocio" ||
        infoFile.items.Stage?.Name === "AsignarSolicitud" ||
        (infoFile.items.Stage?.Name === "Validación Documental" &&
          infoFile.items?.WorkFlow?.WorkflowInstance?.CurrentStatus !==
            "Validación pendiente a ejecutar por el usuario")
      ) {
        setLoadViewMain(true);
        setTimeout(() => {
          getInfoDispatch();
        }, 5000);
      }
    } else if (infoFile.error !== undefined) {
      setLoadViewMain(false);
      if (infoFile.error == "unauthorized") {
        if (showAlertLogout) {
          setShowAlertLogout(false);
          Alert(
            {
              icon: "error",
              title: "Error de autentificación, inicia sesión nuevamente",
              allowOutsideClick: false,
              allowEscapeKey: false,
              onlyOnSuccess: true,
            },
            () => {
              console.log("On accept logout 3");
              navigation("/auth/login");
              Logout();
            }
          );
        }
      } else {
        alert(infoFile.error);
      }
    }
  }, [infoFile]);

  useEffect(() => {
    if (biometrics.items !== undefined) {
      setBiometricInfo(biometrics.items);
    }
  }, [biometrics]);

  useEffect(() => {
    if (wfContinueData.items !== undefined && wfContinueRequest) {
      setWFContinueRequest(false);
      getInfoDispatch();
    } else if (wfContinueData.error !== undefined && wfContinueRequest) {
      setWFContinueRequest(false);
    }
  }, [wfContinueData]);

  useEffect(() => {
    if (wfRejectData.items !== undefined && wfRejectRequest) {
      setWFRejectRequest(false);
      getInfoDispatch();
      // setLoadView(false);
    } else if (wfRejectData.error !== undefined && wfRejectRequest) {
      setWFRejectRequest(false);
      setLoadView(false);
    }
  }, [wfRejectData]);

  useEffect(() => {
    if (returnStageData.items != null) {
      // setLoadView(false)
      getInfoDispatch();
    } /*  else if (returnStageData.error != null) {
      setLoadView(false)
    } */
  }, [returnStageData]);

  const customValidFile = useSelector((state) => state.CustomValidationFile);
  const [infoValidationFile, setInfoValidationFile] = useState([]);

  useEffect(() => {
    if (customValidFile.items !== undefined) {
      setInfoValidationFile(customValidFile.items);
    }
  }, [customValidFile]);

  useEffect(() => {
    // setLoadView(fileUpdateData.loading);
    if (fileUpdateData.items !== undefined && fileUpdateMetFile) {
      if (path.includes(routes.alertaNotificaciones)) {
        setLoadView(false);
        history(routes.home);
        return;
      }
      setLoadViewMain(false);
      setFileUpdateMetFile(false);
      if (sendCuestionarioContinue) {
        setSendCuestionarioContinue(false);
        // setLoadView(false);
        console.log("sendedFromTemp::", sendedFromTemp);
        if (!sendedFromTemp) {
          getInfoDispatch();
        } else {
          setLoadView(false);
          setSendedFromTemp(false);
        }
      } else {
        setApprovedValideProcess(true);
        dispatch(validationActions.ProcessValidationRules(id));
      }
    } else if (fileUpdateData.error !== undefined && fileUpdateMetFile) {
      setLoadViewMain(false);
      setLoadView(false);
      if (infoFile.error == "unauthorized") {
        if (showAlertLogout) {
          setShowAlertLogout(false);
          Alert(
            {
              icon: "error",
              title: "Error de autentificación, inicia sesión nuevamente",
              allowOutsideClick: false,
              allowEscapeKey: false,
              onlyOnSuccess: true,
            },
            () => {
              console.log("On accept logout 1");
              navigation("/auth/login");
              Logout();
            }
          );
        }
      } else {
        setFileUpdateMetFile(false);
      }
    }
  }, [fileUpdateData]);

  useEffect(() => {
    if (processRulesData.error !== undefined && approvedValideProcess) {
      setLoadView(false);
      setApprovedValideProcess(false);
      alert(processRulesData.error);
    } else if (processRulesData.items !== undefined && approvedValideProcess) {
      // setLoadView(false);
      setApprovedValideProcess(false);
      getInfoDispatch();
    }
  }, [processRulesData]);

  const handleSendFinaliceProcess = (e) => {
    let pedingValidation = listEquivalencias?.filter(
      (item) => item.validacion == false
    );
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      let objRequest = {
        File_Id: id,
        Status: "Completo",
        Result: "Completo",
      };
      dispatch(workFlowActions.ContinueWorkFlow(objRequest));
      setWFContinueRequest(true);
      setOpenHelpFinalice(false);
    }
  };

  const handleSendLeftFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: "Completo",
      Result: "Rechazado",
    };
    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
    setWFContinueRequest(true);
    setOpenHelpFinReject(false);
  };

  const handleSendRightFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: "Completo",
      Result: "Recaptura",
    };

    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
    setWFContinueRequest(true);
    setOpenHelpFinReject(false);
  };

  const handleSaveFile = async (formData, e) => {
    let ListMetdata = [];

    infoFile.items?.Stage?.QuestionGroups?.forEach((meta, i) => {
      meta?.Questions.forEach((itemQ) => {
        ListMetdata.push({
          GroupName: meta?.Name,
          Name: itemQ?.Name,
          Value:
            itemQ.QuestionType === "Fecha"
              ? formData[itemQ.Id] === ""
                ? null
                : moment(formData[itemQ.Id]).format("YYYY/MM/DD")
              : formData[itemQ.Id],
          ApprovedConfidence: null,
          Confidence: null,
          MetadataType: null,
          ValueType: null,
        });
      });
    });

    // console.log("ListMetdata: ", ListMetdata)

    let Request = {
      ObjectId: id,
      Metadata: ListMetdata,
      ContinueWorkFlow: false,
    };

    dispatch(fileActions.FileUpdateMetadata(Request)); //Here
    setFileUpdateMetFile(true);
    setLoadView(true);
    setSendedFromTemp(false);
  };

  const handleOneClickFinaliceProcess = (e) => {
    let pedingValidation = listEquivalencias.filter(
      (item) => item.validacion == false
    );
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      setOpenHelpFinalice(true);
    }
  };

  const handleFinaliceValidaction = (objReturned, e) => {
    const isValidateByFile =
      infoFile?.items?.Stage.Properties?.find(
        (prop) => prop.Name == "ValidateByFile"
      )?.Value == "1";
    if (isValidateByFile) {
      if (objReturned?.IsReject) {
        dispatch(
          workFlowActions.RejectWorkFlow({
            File_Id: id,
            Status: "Rechazado",
            Result: "Rechazado",
            Stage_Id: infoFile?.items?.Stage?.Id,
            Comments: objReturned?.Comments ?? "",
          })
        );
        setWFRejectRequest(true);
        setLoadView(true);
      } else {
        dispatch(
          workFlowActions.ContinueWorkFlow({
            File_Id: id,
            Status: "Completo",
            Result: "Completo",
            Stage_Id: infoFile?.items?.Stage?.Id,
            Comments: "",
          })
        );
        setWFContinueRequest(true);
        setLoadView(true);
      }
    } else {
      let objRequest = {
        File_Id: id,
        Status: "Completo",
        Result: objReturned?.IsReject === true ? "Rechazado" : "Completo",
      };
      dispatch(workFlowActions.ContinueWorkFlow(objRequest));
      setWFContinueRequest(true);
    }
  };

  const handleContinueMessage = (e) => {
    getInfoDispatch();
  };
  const handleTicketsTable = () => {
    setStatusView("table");
  };
  const handleTicketsStatus = () => {
    setStatusView("status");
  };
  const handleSeeTicket = (id) => {
    const ticket = ticketItems.find((tk) => tk.Id == id);
    setStatusView("ticket");
    setTicketSelected(ticket);
  };
  const handleSeeFollowing = (id) => {
    const ticket = ticketItems.find((tk) => tk.Id == id);
    setStatusView("following");
    setTicketSelected(ticket);
  };
  const handleNewTicket = () => {
    setStatusView("newTicket");
  };

  const showActionsByRol = () => {
    const showActionsProp = infoFile.items?.IsAssignedUser;
    return showActionsProp;
  };

  const handleReturnStage = () => {
    //Return stage function
    setLoadView(true);
    dispatch(
      workFlowActions.ReturnStage({ File_Id: id, WorkFlowInstanceId: null })
    );
  };

  const returnActionValidate =
    userFuntions?.find((fc) => fc.Name == "Regresar Solicitud") && //Regresar Solicitud
    infoFile?.items?.Stage?.Properties?.find(
      (prop) => prop.Name == "ActiveStageReturn"
    )?.Value == "1" &&
    (infoFile.items?.Stage?.Name === "Cuestionario dinamico Documento" ||
      (infoFile.items?.Stage?.QuestionGroups?.length > 0 &&
        infoFile.items?.Stage?.Name === "Cuestionario dinamico"))
      ? () => handleReturnStage()
      : null;

  return (
    <div className={localStyles.container}>
      {loadViewMain || loadView == true ? (
        <CustomLoadingOverlay
          active={loadViewMain || loadView}
          text="Espera un momento por favor..."
        />
      ) : null}

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: localStyles.indicator,
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Datos"
            {...a11yProps(0)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Información de expediente"
            {...a11yProps(1)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Acciones"
            {...a11yProps(2)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Documentación"
            {...a11yProps(3)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            hidden={rolUser !== "Tickets"}
            label="Seguimiento"
            {...a11yProps(4)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Validaciones"
            {...a11yProps(5)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Resultados biométricos"
            {...a11yProps(6)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
          <Tab
            label="Bitácora de auditoría"
            {...a11yProps(7)}
            classes={{
              root: localStyles.tabRoot,
              selected: localStyles.selectedTab,
            }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div>
            {infoFile.error && <p>{infoFile.error}</p>}
            {infoFile.loading || actor.loading ? (
              <div className={localStyles.spinSection}>
                {/* <CustomLoadingOverlay
                  active={infoFile.loading}
                  text="Espera un momento por favor..."
                /> */}
              </div>
            ) : Object.keys(currentActor).length !== 0 &&
              actor.items !== undefined ? (
              <div className={localStyles.scroll}>
                <ActorGralInfo
                  data={actor.items}
                  docs={actorCards}
                  val={validation}
                  getInfoDispatch={getActorInfoDispatch}
                />
              </div>
            ) : (
              infoFile.items !== undefined && (
                <div className={localStyles.scroll}>
                  <GeneralInfo
                    data={infoFile.items}
                    docs={docsCard}
                    val={validation}
                    actors={multipleActors}
                    getInfoDispatch={() => {
                      getInfoDispatch();
                    }}
                  />
                </div>
              )
            )}
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions
                LabelTitle="Información de expediente"
                onClickConfirm={() => {
                  dispatch(fileActions.GetRecordInfo(actorBody));
                  dispatch(
                    validationActions.GetCustomValidationFile(actorBody)
                  );
                  getInfoDispatch();
                }}
                ConfirmButtonText="Actualizar"
              />

              {record.error && <p>{record.error}</p>}
              {record.loading ? (
                <div className={localStyles.spinSection}>
                  {/* <CustomLoadingOverlay
                    active={record.loading}
                    text="Espera un momento por favor..."
                  /> */}
                </div>
              ) : infoRecordItems.length > 0 ||
                infoValidationFile.length > 0 ? (
                <RecordInfo
                  data={infoRecordItems}
                  dataValidationFile={infoValidationFile}
                />
              ) : infoRecordItems.length === 0 ? (
                <div className={noInfo.noData}>
                  <UseImage src={noDataToShow} type="img" />
                  <Typography variant="h6" className={noInfo.h6}>
                    Sin datos para mostrar
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          {
            //Acciones
            path.includes(routes.alertaNotificaciones) ? (
              <div>
                <div className={localStyles.scroll}>
                  <GenericHeaderActions
                    LabelTitle={"Registra los datos faltantes"}
                    ConfirmButtonStartIcon={<RefreshIcon />}
                    onClickConfirm={() => {
                      getInfoDispatch();
                    }}
                    ConfirmButtonText="Actualizar"
                  />
                  <FormDynamicTemp
                    questions={metadataFillNotifications?.items}
                    handleSave={(formData, e) => {
                      setLoadView(true);
                      let ListMetdata = [];

                      const qGroups = [
                        {
                          Description: "Sistema Proveedor",
                          Id: "001",
                          Name: "Sistema Proveedor",
                          Questions: metadataFillNotifications?.items,
                        },
                      ];

                      qGroups?.forEach((meta, i) => {
                        meta?.Questions.forEach((itemQ) => {
                          ListMetdata.push({
                            GroupName: meta?.Name,
                            Name: itemQ?.Name,
                            Value: formData[itemQ.Name],
                            ApprovedConfidence: null,
                            Confidence: null,
                            MetadataType: null,
                            ValueType: null,
                          });
                        });
                      });

                      let Request = {
                        ObjectId: id,
                        Metadata: ListMetdata,
                        ContinueWorkFlow: false,
                      };

                      console.log("Request::", Request, formData);
                      dispatch(fileActions.FileUpdateMetadata(Request)); //Here
                      setFileUpdateMetFile(true);
                      setLoadView(true);
                    }}
                  />
                </div>
              </div>
            ) : showActionsByRol() ? (
              <div>
                <div className={localStyles.scroll}>
                  <GenericHeaderActions
                    LabelTitle={
                      infoFile.items?.Status ===
                      "Validación Documental por HR Analyst"
                        ? "Verifica que la documentación cargada por el candidato sea correcta y aprueba o rechaza según sea el caso."
                        : infoFile.items?.Status ===
                          "Validación Documental por HR Analyst 2da validación"
                        ? "Verifica que la documentación cargada por el verificador de antecedentes sea correcta y aprueba o rechaza según sea el caso."
                        : infoFile.items?.Status
                    }
                    ConfirmButtonStartIcon={<RefreshIcon />}
                    onClickConfirm={() => {
                      getInfoDispatch();
                    }}
                    ConfirmButtonText="Actualizar"
                    onClickCancel={returnActionValidate}
                    CancelButtonText={"Regresar"}
                  />
                  {infoFile.items !== undefined &&
                  infoFile.items?.Stage?.Name === "Carga documental" ? (
                    <DocumentUploadComponent Data={infoFile.items} />
                  ) : null}

                  {infoFile.items?.Stage?.Name ===
                  "Información de la Solicitud" ? (
                    <InformationalMessageComponent
                      actionResset={handleContinueMessage}
                      Data={infoFile.items}
                      refresh={() => {
                        getInfoDispatch();
                      }}
                    />
                  ) : null}

                  {infoFile.items !== undefined &&
                  (infoFile.items?.StageName.toLowerCase() ===
                    "validación de mesa de control" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "validación documental" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "validación de documentos" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "captura de datos por hr analyst" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "validación kit contratacion" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "cuestionario dinamico" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "cuestionario dinamico documento" ||
                    infoFile.items?.StageName.toLowerCase() ===
                      "validaciones") ? (
                    <>
                      {infoFile.itemsDocCardValidation?.length > 0 &&
                      infoFile.items?.Stage?.Name !==
                        "Cuestionario dinamico Documento" ? (
                        <Documentation
                          IsValidate={true}
                          cards={infoFile.itemsDocCardValidation}
                          val={validation}
                          docsA={docsApproved}
                          cActor={currentActor}
                          refresh={getInfoDispatch}
                          bioData={biometricInfo}
                          FinaliceValidaction={handleFinaliceValidaction}
                          fileId={id}
                          activaFinalizaValidacion={true}
                        />
                      ) : null}

                      {infoFile.items?.Stage?.Name ===
                        "Cuestionario dinamico Documento" ||
                      (infoFile.items?.Stage?.QuestionGroups?.length > 0 &&
                        infoFile.items?.Stage?.Name ===
                          "Cuestionario dinamico") ? (
                        <FormDynamic
                          fileId={id}
                          loadViewInfo={loadView}
                          StageCurrent={infoFile.items}
                          handleUnmountForm={(data) => {
                            console.log('Lo guarda: ',data)
                            setRequestFormTemp(data);
                          }}
                          handleSave={(formData, e) => {
                            setLoadView(true);
                            let ListMetdata = [];

                            const qGroups =
                              infoFile.items?.Stage?.Name ==
                              "Cuestionario dinamico Documento"
                                ? infoFile.items?.Stage?.DocumentalCategory
                                    ?.DocumentalGroups[0]?.DocumentTypes[0]
                                    ?.QuestionGroups
                                : infoFile.items?.Stage?.QuestionGroups;

                            qGroups?.forEach((meta, i) => {
                              meta?.Questions.forEach((itemQ) => {
                                ListMetdata.push({
                                  GroupName: meta?.Name,
                                  Name: itemQ?.Name,
                                  Value:
                                    itemQ?.QuestionType === "Fecha" ||
                                    itemQ.QuestionType === "FechaPrevia"
                                      ? formData[itemQ.Id] === "" ||
                                        formData[itemQ.Id] === undefined ||
                                        formData[itemQ.Id] === null
                                        ? null
                                        : moment(formData[itemQ.Id]).format(
                                            "YYYY/MM/DD"
                                          )
                                      : itemQ.QuestionType === "Currency"
                                      ? formData[itemQ.Id]
                                          ?.replace("$", "")
                                          ?.replace(",", "")
                                          ?.replace(",", "")
                                      : formData[itemQ.Id],
                                  ApprovedConfidence: null,
                                  Confidence: null,
                                  MetadataType: null,
                                  ValueType: null,
                                });
                              });
                            });

                            const totalPercentage = ListMetdata?.filter(
                              (field) =>
                                field?.Name.toUpperCase()?.includes(
                                  "PORCENTAJE"
                                ) &&
                                field.Value != "" &&
                                field.Value !== undefined &&
                                field.Value !== null
                            );
                            const totalFiltered = totalPercentage.reduce(
                              (acumulador, num) =>
                                parseInt(acumulador) + parseInt(num.Value),
                              0
                            );

                            if (
                              totalPercentage.length > 0 &&
                              totalFiltered != 100
                            ) {
                              setLoadView(false);
                              return Alert({
                                icon: "error",
                                title:
                                  "Verifica que la suma de los porcentajes sea de 100%",
                              });
                            }

                            let Request = {
                              ObjectId: id,
                              Metadata: ListMetdata,
                              ContinueWorkFlow: true,
                            };
                            setSendCuestionarioContinue(true);
                            setSendedFromTemp(false);
                            updateAssignUser();
                            dispatch(fileActions.FileUpdateMetadata(Request)); //Here
                            const sendInvestigator =
                              infoFile.items?.Metadata?.find(
                                (meta) => meta.Name === "Enviar a investigación"
                              );
                            const stageId = infoFile.items?.Stage?.Id;
                            console.log(
                              "Enviar a investigacion: ",
                              sendInvestigator?.Value,
                              stageId
                            );
                            if (
                              sendInvestigator?.Value?.toUpperCase() === "SI" &&
                              stageId == 544 &&
                              !loadView
                            ) {
                              Alert(
                                {
                                  icon: "success",
                                  title:
                                    "La solicitud ha sido enviada a Investigación",
                                },
                                () => {
                                  setFileUpdateMetFile(true);
                                  setLoadView(true);
                                }
                              );
                            } else {
                              setFileUpdateMetFile(true);
                              setLoadView(true);
                            }
                          }}
                        />
                      ) : infoFile.items?.Stage?.QuestionGroups?.length > 0 ? (
                        <Accordion classes={{ root: localStyles.group }}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={localStyles.textGroup}>
                              Cuestionario
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={localStyles.details}>
                            <FormDynamic
                              StageCurrent={infoFile.items}
                              handleSave={handleSaveFile}
                            />
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                    </>
                  ) : null}

                  {infoFile.error && <p>{infoFile.error}</p>}
                  {(infoFile.loading || actor.loading) &&
                  listEquivalencias?.length <= 0 ? (
                    <div className={localStyles.spinSection}></div>
                  ) : infoFile.items?.Stage?.Name !== "Carga documental" &&
                    infoFile.items?.Stage?.Name !==
                      "Cuestionario dinamico Documento" &&
                    infoFile.items?.Stage?.Name !==
                      "Información de la Solicitud" &&
                    infoFile.items?.Stage?.Name !== "Validación Documental" &&
                    infoFile.items?.Status !==
                      "Validación Documental por HR Analyst" &&
                    infoFile.items?.Stage?.Name !== "Cuestionario dinamico" &&
                    infoFile.items?.Status !==
                      "Captura de datos por HR Analyst" ? (
                    <EquivalenciasTables
                      items={listEquivalencias}
                      val={validation}
                      refresh={getInfoDispatch}
                      fileId={id}
                      status={infoFile.items?.Status}
                    />
                  ) : null}
                </div>

                <CustomAlert
                  closeAction={() => {
                    setOpenHelpFinalice(false);
                  }}
                  open={openHelpFinalice}
                  title="Finalizar validación"
                  body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
                  leftButtonText="Cancelar"
                  leftButtonAction={() => {
                    setOpenHelpFinalice(false);
                  }}
                  rightButtonText="Aceptar"
                  rightButtonAction={handleSendFinaliceProcess}
                />

                <CustomAlert
                  closeAction={() => {
                    setOpenHelpFinReject(false);
                  }}
                  open={openHelpFinReject}
                  title="Finalizar validación"
                  body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
                  leftButtonText="Rechazar solicitud"
                  leftButtonAction={handleSendLeftFinalice}
                  rightButtonText="Solicitar documentos"
                  rightButtonAction={handleSendRightFinalice}
                />
              </div>
            ) : (
              <NoShares
                title={
                  infoFile.items?.Stage?.Name === "AsignacionMetadatos"
                    ? infoFile.items?.Stage?.Description
                    : ""
                }
              />
            )
          }
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions
                LabelTitle="Documentación cargada"
                toolTip={
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography variant="p" className={localStyles.docs}>
                          Estatus de la documentación:
                        </Typography>
                        <br></br>
                        <Typography variant="p" className={localStyles.docs}>
                          {" "}
                          <CheckCircleIcon style={{ color: "#00847F" }} />
                          Documento aprobado
                        </Typography>
                        <br></br>
                        <Typography variant="p" className={localStyles.docs}>
                          {" "}
                          <CancelIcon style={{ color: "red" }} />
                          Documento rechazado
                        </Typography>
                        <br></br>
                        <Typography variant="p" className={localStyles.docs}>
                          {" "}
                          <CircleIcon style={{ color: "#00847F" }} />
                          Documento cargado
                        </Typography>
                      </React.Fragment>
                    }
                    placement="bottom-start"
                  >
                    <HelpIcon />
                  </HtmlTooltip>
                }
                onClickConfirm={() => {
                  getInfoDispatch();
                  getActorInfoDispatch();
                }}
                ConfirmButtonText="Actualizar"
                ConfirmButtonStartIcon={<RefreshIcon />}
              />

              {infoFile.error && <p>{infoFile.error}</p>}
              {infoFile.loading || actor.loading ? (
                <div className={localStyles.spinSection}>
                  {/* <CustomLoadingOverlay
                    active={infoFile.loading}
                    text="Espera un momento por favor..."
                  /> */}
                </div>
              ) : actorCards?.length > 0 &&
                Object.keys(currentActor).length !== 0 ? (
                <Documentation
                  cards={actorCards}
                  val={validation}
                  docsA={docsApproved}
                  cActor={currentActor}
                  refresh={getActorInfoDispatch}
                  fileId={id}
                />
              ) : (
                <Documentation
                  IsValidate={false}
                  cards={
                    documentsRolUser?.itemsReorder?.length == 0
                      ? docsCard
                      : documentsRolUser?.itemsReorder
                  }
                  val={validation}
                  docsA={docsApproved}
                  cActor={currentActor}
                  refresh={getInfoDispatch}
                  bioData={biometricInfo}
                  fileId={id}
                />
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          {statusView === "status" ? (
            <TicketStatusView
              onClickReturn={() => handleTicketsTable()}
              folio={infoFile?.items?.Folio}
              data={ticketStatusItems?.GruposProcesos}
            />
          ) : statusView === "table" ? (
            <TicketsTableView
              onClickReturn={() => handleTicketsStatus()}
              onClickSee={(idRow) => {
                handleSeeTicket(idRow);
              }}
              onClickFollow={(idRow) => {
                handleSeeFollowing(idRow);
              }}
              folio={infoFile?.items?.Folio}
              onSearch={(searchText) => {
                getTicketSearchDispatch(searchText);
              }}
              onClearSearch={() => {
                getTicketSearchDispatch("" /* infoFile?.items?.Folio */);
              }}
              onClickNew={() => {
                handleNewTicket();
              }}
            />
          ) : statusView === "ticket" ? (
            <TicketSelectedView
              TicketId={ticketSelected?.Id}
              folio={infoFile?.items?.Folio}
              onClickReturn={() => {
                setStatusView("table");
              }}
            />
          ) : statusView === "newTicket" ? (
            <TicketNewView
              folio={infoFile?.items?.Folio}
              onClickReturn={() => {
                setStatusView("table");
              }}
              statusData={ticketStatusItems?.GruposProcesos}
            />
          ) : statusView === "following" ? (
            <TicketFollowing
              folio={infoFile?.items?.Folio}
              onClickReturn={() => {
                setStatusView("table");
              }}
              TicketId={ticketSelected?.Id}
            />
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={5} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions
                LabelTitle="Equivalencias"
                onClickConfirm={handleOneClickFinaliceProcess}
                ConfirmButtonText="Finalizar"
                ConfirmButtonDisabled={!viewButtonFinalice}
              />

              {infoFile.error && <p>{infoFile.error}</p>}
              {(infoFile.loading || actor.loading) &&
              listEquivalencias?.length <= 0 ? (
                <div className={localStyles.spinSection}>
                  {/* <CustomLoadingOverlay
                    active={infoFile.loading}
                    text="Espera un momento por favor..."
                  /> */}
                </div>
              ) : (
                <EquivalenciasTables
                  items={listEquivalencias}
                  val={validation}
                  refresh={getInfoDispatch}
                  fileId={id}
                  status={infoFile.items?.Status}
                />
              )}

              {infoFile.items !== undefined &&
              (infoFile.items?.Status === "Validación de Mesa de control" ||
                infoFile.items?.Status === "Validación documental" ||
                infoFile.items?.Status === "Validación de documentos") &&
              infoFile.items?.Stage?.QuestionGroups?.length > 0 ? (
                <Accordion classes={{ root: localStyles.group }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={localStyles.textGroup}>
                      Cuestionario
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={localStyles.details}>
                    <FormDynamic
                      StageCurrent={infoFile.items}
                      handleSave={handleSaveFile}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </div>

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinalice(false);
              }}
              open={openHelpFinalice}
              title="Finalizar validación"
              body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
              leftButtonText="Cancelar"
              leftButtonAction={() => {
                setOpenHelpFinalice(false);
              }}
              rightButtonText="Aceptar"
              rightButtonAction={handleSendFinaliceProcess}
            />

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinReject(false);
              }}
              open={openHelpFinReject}
              title="Finalizar validación"
              body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
              leftButtonText="Rechazar solicitud"
              leftButtonAction={handleSendLeftFinalice}
              rightButtonText="Solicitar documentos"
              rightButtonAction={handleSendRightFinalice}
            />
          </div>
        </TabPanel>

        <TabPanel value={value} index={6} dir={theme.direction}>
          <div>
            {biometrics.loading ? (
              <div className={localStyles.spinSection}>
                {/* <CustomLoadingOverlay
                  active={biometrics.loading}
                  text="Espera un momento por favor..."
                /> */}
              </div>
            ) : biometrics.items !== undefined &&
              (biometrics.items?.Face.Selfie !== null ||
                biometrics.items?.SpeechToText.Audio !== null ||
                biometrics.items?.SpeechToText.Video !== null ||
                biometrics.items?.Fingerprints.Fingerprint2 !== null ||
                biometrics.items?.Fingerprints.Fingerprint7 !== null) ? (
              <div className={localStyles.scroll}>
                <Biometrics
                  bioData={biometricInfo}
                  selfArray={biometrics.itemsSelfie}
                  setBioDispatch={setBioDispatch}
                  docMetAdic={docsCard}
                />
              </div>
            ) : (
              <div className={noInfo.noData}>
                <UseImage src={noBio} type="img" style={{ maxWidth: 260 }} />
                <Typography variant="h6" className={noInfo.h6}>
                  Sin datos biométricos
                </Typography>
              </div>
            )}
          </div>
        </TabPanel>

        <TabPanel value={value} index={7} dir={theme.direction}>
          <div>
            <GenericHeaderActions
              LabelTitle="Bitácora de auditoría"
              onClickConfirm={() => {
                dispatch(workFlowActions.GetBinnacleInfo(actorBody));
              }}
              ConfirmButtonText="Actualizar"
              ConfirmButtonStartIcon={<RefreshIcon />}
            />
            {info.error && <p>{info.error}</p>}
            {info.loading ? (
              <div className={localStyles.spinSection}>
                {/* <CustomLoadingOverlay
                  active={info.loading}
                  text="Espera un momento por favor..."
                /> */}
              </div>
            ) : infoItems ? (
              <div className={localStyles.scroll1}>
                <TableBinnacle data={infoItems} />
              </div>
            ) : null}
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
